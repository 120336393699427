import * as Icons from './components';

const icons = {
  activity: Icons.Activity,
  airplay: Icons.Airplay,
  'alert-circle': Icons.AlertCircle,
  'alert-octagon': Icons.AlertOctagon,
  'alert-triangle': Icons.AlertTriangle,
  'align-center': Icons.AlignCenter,
  'align-justify': Icons.AlignJustify,
  'align-left': Icons.AlignLeft,
  'align-right': Icons.AlignRight,
  anchor: Icons.Anchor,
  aperture: Icons.Aperture,
  archive: Icons.Archive,
  'arrow-down-circle': Icons.ArrowDownCircle,
  'arrow-down-left': Icons.ArrowDownLeft,
  'arrow-down-right': Icons.ArrowDownRight,
  'arrow-down': Icons.ArrowDown,
  'arrow-left-circle': Icons.ArrowLeftCircle,
  'arrow-left': Icons.ArrowLeft,
  'arrow-right-circle': Icons.ArrowRightCircle,
  'arrow-right': Icons.ArrowRight,
  'arrow-up-circle': Icons.ArrowUpCircle,
  'arrow-up-left': Icons.ArrowUpLeft,
  'arrow-up-right': Icons.ArrowUpRight,
  'arrow-up': Icons.ArrowUp,
  'at-sign': Icons.AtSign,
  award: Icons.Award,
  'bar-chart-2': Icons.BarChart2,
  'bar-chart': Icons.BarChart,
  'battery-charging': Icons.BatteryCharging,
  battery: Icons.Battery,
  'bell-off': Icons.BellOff,
  bell: Icons.Bell,
  bluetooth: Icons.Bluetooth,
  bold: Icons.Bold,
  'book-open': Icons.BookOpen,
  book: Icons.Book,
  bookmark: Icons.Bookmark,
  box: Icons.Box,
  briefcase: Icons.Briefcase,
  calendar: Icons.Calendar,
  'camera-off': Icons.CameraOff,
  camera: Icons.Camera,
  cast: Icons.Cast,
  'check-circle': Icons.CheckCircle,
  'check-square': Icons.CheckSquare,
  check: Icons.Check,
  'chevron-down': Icons.ChevronDown,
  'chevron-left': Icons.ChevronLeft,
  'chevron-right': Icons.ChevronRight,
  'chevron-up': Icons.ChevronUp,
  'chevrons-down': Icons.ChevronsDown,
  'chevrons-left': Icons.ChevronsLeft,
  'chevrons-right': Icons.ChevronsRight,
  'chevrons-up': Icons.ChevronsUp,
  chrome: Icons.Chrome,
  circle: Icons.Circle,
  clipboard: Icons.Clipboard,
  clock: Icons.Clock,
  'cloud-drizzle': Icons.CloudDrizzle,
  'cloud-lightning': Icons.CloudLightning,
  'cloud-off': Icons.CloudOff,
  'cloud-rain': Icons.CloudRain,
  'cloud-snow': Icons.CloudSnow,
  cloud: Icons.Cloud,
  code: Icons.Code,
  codepen: Icons.Codepen,
  codesandbox: Icons.Codesandbox,
  coffee: Icons.Coffee,
  columns: Icons.Columns,
  command: Icons.Command,
  compass: Icons.Compass,
  copy: Icons.Copy,
  'corner-down-left': Icons.CornerDownLeft,
  'corner-down-right': Icons.CornerDownRight,
  'corner-left-down': Icons.CornerLeftDown,
  'corner-left-up': Icons.CornerLeftUp,
  'corner-right-down': Icons.CornerRightDown,
  'corner-right-up': Icons.CornerRightUp,
  'corner-up-left': Icons.CornerUpLeft,
  'corner-up-right': Icons.CornerUpRight,
  cpu: Icons.Cpu,
  'credit-card': Icons.CreditCard,
  crop: Icons.Crop,
  crosshair: Icons.Crosshair,
  database: Icons.Database,
  delete: Icons.Delete,
  disc: Icons.Disc,
  'dollar-sign': Icons.DollarSign,
  'download-cloud': Icons.DownloadCloud,
  download: Icons.Download,
  droplet: Icons.Droplet,
  'edit-2': Icons.Edit2,
  'edit-3': Icons.Edit3,
  edit: Icons.Edit,
  'external-link': Icons.ExternalLink,
  'eye-off': Icons.EyeOff,
  eye: Icons.Eye,
  facebook: Icons.Facebook,
  'fast-forward': Icons.FastForward,
  feather: Icons.Feather,
  figma: Icons.Figma,
  'file-minus': Icons.FileMinus,
  'file-plus': Icons.FilePlus,
  'file-text': Icons.FileText,
  file: Icons.File,
  film: Icons.Film,
  filter: Icons.Filter,
  flag: Icons.Flag,
  'folder-minus': Icons.FolderMinus,
  'folder-plus': Icons.FolderPlus,
  folder: Icons.Folder,
  framer: Icons.Framer,
  frown: Icons.Frown,
  gift: Icons.Gift,
  'git-branch': Icons.GitBranch,
  'git-commit': Icons.GitCommit,
  'git-merge': Icons.GitMerge,
  'git-pull-request': Icons.GitPullRequest,
  github: Icons.GitHub,
  gitlab: Icons.Gitlab,
  globe: Icons.Globe,
  grid: Icons.Grid,
  'hard-drive': Icons.HardDrive,
  hash: Icons.Hash,
  headphones: Icons.Headphones,
  heart: Icons.Heart,
  'help-circle': Icons.HelpCircle,
  hexagon: Icons.Hexagon,
  home: Icons.Home,
  image: Icons.Image,
  inbox: Icons.Inbox,
  info: Icons.Info,
  instagram: Icons.Instagram,
  italic: Icons.Italic,
  key: Icons.Key,
  layers: Icons.Layers,
  layout: Icons.Layout,
  'life-buoy': Icons.LifeBuoy,
  'link-2': Icons.Link2,
  link: Icons.Link,
  linkedin: Icons.Linkedin,
  list: Icons.List,
  loader: Icons.Loader,
  lock: Icons.Lock,
  'log-in': Icons.LogIn,
  'log-out': Icons.LogOut,
  mail: Icons.Mail,
  'map-pin': Icons.MapPin,
  map: Icons.Map,
  'maximize-2': Icons.Maximize2,
  maximize: Icons.Maximize,
  meh: Icons.Meh,
  menu: Icons.Menu,
  'message-circle': Icons.MessageCircle,
  'message-square': Icons.MessageSquare,
  'mic-off': Icons.MicOff,
  mic: Icons.Mic,
  'minimize-2': Icons.Minimize2,
  minimize: Icons.Minimize,
  'minus-circle': Icons.MinusCircle,
  'minus-square': Icons.MinusSquare,
  minus: Icons.Minus,
  monitor: Icons.Monitor,
  moon: Icons.Moon,
  'more-horizontal': Icons.MoreHorizontal,
  'more-vertical': Icons.MoreVertical,
  'mouse-pointer': Icons.MousePointer,
  move: Icons.Move,
  music: Icons.Music,
  'navigation-2': Icons.Navigation2,
  navigation: Icons.Navigation,
  octagon: Icons.Octagon,
  package: Icons.Package,
  paperclip: Icons.Paperclip,
  'pause-circle': Icons.PauseCircle,
  pause: Icons.Pause,
  'pen-tool': Icons.PenTool,
  percent: Icons.Percent,
  'phone-call': Icons.PhoneCall,
  'phone-forwarded': Icons.PhoneForwarded,
  'phone-incoming': Icons.PhoneIncoming,
  'phone-missed': Icons.PhoneMissed,
  'phone-off': Icons.PhoneOff,
  'phone-outgoing': Icons.PhoneOutgoing,
  phone: Icons.Phone,
  'pie-chart': Icons.PieChart,
  'play-circle': Icons.PlayCircle,
  play: Icons.Play,
  'plus-circle': Icons.PlusCircle,
  'plus-square': Icons.PlusSquare,
  plus: Icons.Plus,
  pocket: Icons.Pocket,
  power: Icons.Power,
  printer: Icons.Printer,
  radio: Icons.Radio,
  'refresh-ccw': Icons.RefreshCcw,
  'refresh-cw': Icons.RefreshCw,
  repeat: Icons.Repeat,
  rewind: Icons.Rewind,
  'rotate-ccw': Icons.RotateCcw,
  'rotate-cw': Icons.RotateCw,
  rss: Icons.Rss,
  save: Icons.Save,
  scissors: Icons.Scissors,
  search: Icons.Search,
  send: Icons.Send,
  server: Icons.Server,
  settings: Icons.Settings,
  'share-2': Icons.Share2,
  share: Icons.Share,
  'shield-off': Icons.ShieldOff,
  shield: Icons.Shield,
  'shopping-bag': Icons.ShoppingBag,
  'shopping-cart': Icons.ShoppingCart,
  shuffle: Icons.Shuffle,
  sidebar: Icons.Sidebar,
  'skip-back': Icons.SkipBack,
  'skip-forward': Icons.SkipForward,
  slack: Icons.Slack,
  slash: Icons.Slash,
  sliders: Icons.Sliders,
  smartphone: Icons.Smartphone,
  smile: Icons.Smile,
  speaker: Icons.Speaker,
  square: Icons.Square,
  star: Icons.Star,
  'stop-circle': Icons.StopCircle,
  sun: Icons.Sun,
  sunrise: Icons.Sunrise,
  sunset: Icons.Sunset,
  tablet: Icons.Tablet,
  tag: Icons.Tag,
  target: Icons.Target,
  terminal: Icons.Terminal,
  thermometer: Icons.Thermometer,
  'thumbs-down': Icons.ThumbsDown,
  'thumbs-up': Icons.ThumbsUp,
  'toggle-left': Icons.ToggleLeft,
  'toggle-right': Icons.ToggleRight,
  tool: Icons.Tool,
  'trash-2': Icons.Trash2,
  trash: Icons.Trash,
  trello: Icons.Trello,
  'trending-down': Icons.TrendingDown,
  'trending-up': Icons.TrendingUp,
  triangle: Icons.Triangle,
  truck: Icons.Truck,
  tv: Icons.Tv,
  twitch: Icons.Twitch,
  twitter: Icons.Twitter,
  type: Icons.Type,
  umbrella: Icons.Umbrella,
  underline: Icons.Underline,
  unlock: Icons.Unlock,
  'upload-cloud': Icons.UploadCloud,
  upload: Icons.Upload,
  'user-check': Icons.UserCheck,
  'user-minus': Icons.UserMinus,
  'user-plus': Icons.UserPlus,
  'user-x': Icons.UserX,
  user: Icons.User,
  users: Icons.Users,
  'video-off': Icons.VideoOff,
  video: Icons.Video,
  voicemail: Icons.Voicemail,
  'volume-1': Icons.Volume1,
  'volume-2': Icons.Volume2,
  'volume-x': Icons.VolumeX,
  volume: Icons.Volume,
  watch: Icons.Watch,
  'wifi-off': Icons.WifiOff,
  wifi: Icons.Wifi,
  wind: Icons.Wind,
  'x-circle': Icons.XCircle,
  'x-octagon': Icons.XOctagon,
  'x-square': Icons.XSquare,
  x: Icons.X,
  youtube: Icons.Youtube,
  'zap-off': Icons.ZapOff,
  zap: Icons.Zap,
  'zoom-in': Icons.ZoomIn,
  'zoom-out': Icons.ZoomOut,
};

export default icons;
